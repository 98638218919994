<template>
  <div>
    <div class="nav-bar">
      <div class="nav-bar-wrap">
        <q-icon name="arrow_back_ios" color="white" size="18px" @click="onBack"></q-icon>

        <div class="nav-conten">
          <slot name="center">
            <div class="nav-title">{{ title }}</div>
          </slot>
        </div>

        <slot name="right"></slot>
      </div>
    </div>

    <!-- 占位符 -->
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    autoReturn: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {

    }
  },
  methods: {
    onBack() {
      if (this.autoReturn) {
        this.$router.back()
      }
      this.$emit('onBack')
    }
  }
}
</script>

<style scoped>
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #00000099;
  z-index: 9;
}

.nav-bar-wrap {
  position: relative;
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(~@/assets/img/base/header-bg.png) no-repeat;
  background-size: 100% 100%;
  background-position: 100% 0;
}

.nav-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.placeholder {
  position: relative;
  height: 70px;
}
</style>