<template>
  <div class="sidebar">
    <div class="close-wrap">
      <img src="@/assets/img/base/sidebar-close.png" width="20" @click="close" />
    </div>

    <div class="container">
      <div class="row items-center">
        <img src="@/assets/img/logo2.png" width="50" />
        <div class="q-ml-md">
          <div class="address">{{ abbr(us.address, 4, 4) }}</div>
          <div class="row q-mt-xs level">
            <div><span class="m-color">{{ $t('级别') }}：</span>{{ us.xlevelName }}</div>
            <div><span class="m-color q-ml-md">{{ $t('速度') }}：</span>{{ us.vlevelName }}</div>
          </div>
        </div>
      </div>


      <div class="menu">
        <div class="menu-item" @click="tolink('bill')">
          <img src="@/assets/img/base/sidebar-zd.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('账单记录') }}</div>
        </div>
        <div class="menu-item" @click="tolink('Bonus')">
          <img src="@/assets/img/base/sidebar-sy.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('我的收益') }}</div>
        </div>
        <div class="menu-item" @click="tolink('Share')">
          <img src="@/assets/img/base/sidebar-fx.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('邀请好友') }}</div>
        </div>
        <div class="menu-item" @click="tolink('news')">
          <img src="@/assets/img/base/sidebar-tg.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('通告') }}</div>
        </div>
        <div class="menu-item">
          <img src="@/assets/img/base/sidebar-sc.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('STC 商城') }}</div>
          <div class="menu-item-label">{{ $t('即将推出') }}</div>
        </div>
        <div class="menu-item" @click="toUniswap">
          <img src="@/assets/img/base/sidebar-otc.png" width="20" height="20" />
          <div class="menu-item-title">Uniswap</div>
        </div>
        <div class="menu-item">
          <img src="@/assets/img/base/sidebar-sd.png" width="20" height="20" />
          <div class="menu-item-title">{{ $t('闪兑') }}</div>
          <div class="menu-item-label">{{ $t('即将推出') }}</div>
        </div>
        <div class="menu-item">
          <img src="@/assets/img/base/sidebar-yyz.png" width="25" height="25" />
          <div class="menu-item-title">{{ $t('元宇宙') }}</div>
          <div class="menu-item-label">{{ $t('即将推出') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    return {
      us: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
  },
  methods: {
    toUniswap() {
      window.open("https://app.uniswap.org/swap/?inputCurrency=0x0000000000000000000000000000000000001010&outputCurrency=0x1d4c19fcd4002bcd8c9226491d50f14427aedd6e")
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    close() {
      this.$emit('close')
    },
    tolink(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 100vw;
  height: 100vh;
  background: url(~@/assets/img/base/sidebar-bg.png) no-repeat;
  background-size: 100% 100%;
}

.close-wrap {
  padding: 20px 33px 0 0;
  display: flex;
  justify-content: flex-end;
}

.container {
  padding: 30px 33px 0;
}

.address {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.level {
  font-size: 12px;
  color: #fff;
}

.menu {
  margin-top: 80px;
}

.menu-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(46, 46, 46, .6);
}

.menu-item-title {
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.menu-item-label {
  margin-left: 10px;
  color: #884DDA;
  font-size: 12px;
}
</style>