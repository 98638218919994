<template>
  <div class="header" ref="header">
    <div class="row items-center">
      <img src="@/assets/img/logo2.png" width="30" class="q-mr-sm" />
      <img src="@/assets/img/base/SuperToss.png" width="63" />
    </div>
    <div class="row items-center">
      <van-popover v-model:show="showPopover" placement="bottom-end" :overlay="true" :teleport="$refs.header">
        <div class="popover-wrap">
          <div class="popover-item" v-for="(item, index) in actions" :key="index" @click="onSelect(item)">
            <img :src="item.img" class="language-icon" />
            <div class="language-text">{{ item.text }}</div>
          </div>
        </div>
        <template #reference>
          <div class="language">
            <div class="q-mr-xs">{{ action.text }}</div>
            <img :src="action.img" width="24" />
          </div>
        </template>
      </van-popover>
      <img src="@/assets/img/base/header-menu.png" width="37" @click="showSidebar = true" />
    </div>

    <div class="bg-block"></div>

    <!-- 侧边栏 -->
    <q-dialog v-model="showSidebar" maximized position="left">
      <sidebar @close="showSidebar = false"></sidebar>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Popover } from 'vant';
import Sidebar from './Sidebar'

export default {
  components: {
    [Popover.name]: Popover,
    Sidebar
  },
  setup() {
    return {
      showSidebar: ref(false),
      showPopover: ref(false),
      defaultLanguage: ref("ch"),//默认语言
      action: ref({}),
      actions: [
        {
          language: "ch",
          text: '中文',
          img: require('@/assets/img/base/zh.png')
        },
        {
          language: "en",
          text: 'English',
          img: require('@/assets/img/base/en.png')
        }
        // {
        //   language: "cht",
        //   text: '繁體',
        //   img: require('@/assets/img/base/zh.png')
        // },
        // {
        //   language: "kor",
        //   text: '한국어',
        //   img: require('@/assets/img/base/kor.png')
        // },
        // {
        //   language: "jp",
        //   text: '日本語',
        //   img: require('@/assets/img/base/jp.png')
        // },
        // {
        //   language: "th",
        //   text: 'ภาษาไทย',
        //   img: require('@/assets/img/base/th.png')
        // }
      ],
    }
  },
  created() {
    let language = this.$utils.getloc("language")
    language = language ? language : this.defaultLanguage
    this.action = this.actions.filter(f => f.language == language)[0]
  },
  methods: {
    onSelect(e) {
      this.action = e
      let language = e.language
      this.$utils.setloc("language", language)
      this.$i18n.locale = language
      this.showPopover = false
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>
.header {
  position: relative;
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.language {
  margin-right: 20px;
  padding: 1px 3px 1px 9px;
  height: 28px;
  border: 1px solid #884DDA;
  box-sizing: border-box;
  border-radius: 99px;
}

.bg-block {
  position: absolute;
  width: 100%;
  height: 186px;
  border-radius: 238px;
  left: 0;
  bottom: -100px;
  background: url(~@/assets/img/base/header-bg.png) no-repeat;
  background-size: 100% 100%;
  z-index: -1
}

.language {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px
}

.popover-wrap {
  color: #fff;
  background: #080535;
  border: 2px solid #f56ef9;
  border-radius: 10px;
  overflow: hidden;
}

.popover-item {
  display: flex;
  padding: 10px 20px;
}

.language-icon {
  width: 23px;
}

.language-text {
  margin-left: 5px;
}

.header /deep/ .van-popover {
  top: 50px !important;
  position: fixed !important;
  border-radius: 10px !important;
}
</style>