<template>
  <div class="tab-bar">
    <div class="bar-wrap">
      <div class="bar-item" :class="[{'bar-float': item.float}, {'bar-active': active == index}]" v-for="(item, index) in list" :key="index"
        @click="onClick(item)">
        <img :src="active == index ? item.activeImg : item.img" class="bar-icon" />
        <div>{{ $t(item.text) }}</div>
      </div>
    </div>

    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: [String, Number],
      default: 0
    }
  },
  setup() {
    return {
      list: [
        {
          text: "首页",
          img: require("@/assets/img/tabbar/m-home.png"),
          activeImg: require("@/assets/img/tabbar/m-home-active.png"),
          url: "/"
        },
        {
          text: "聊天",
          img: require("@/assets/img/tabbar/m-chat.png"),
          activeImg: require("@/assets/img/tabbar/m-chat-active.png"),
          url: "/chat"
        },
        {
          text: "",
          img: require("@/assets/img/tabbar/m-logo.png"),
          activeImg: require("@/assets/img/tabbar/m-logo.png"),
          url: "/jingcai",
          float: true
        },
        {
          text: "资产",
          img: require("@/assets/img/tabbar/m-asset.png"),
          activeImg: require("@/assets/img/tabbar/m-asset-active.png"),
          url: "wallet"
        },
        {
          text: "社区",
          img: require("@/assets/img/tabbar/m-community.png"),
          activeImg: require("@/assets/img/tabbar/m-community-active.png"),
          url: "community"
        }
      ]
    }
  },
  methods: {
    onClick(e) {
      if(e.url) {
        this.$router.push(e.url)
      }
    }
  }
}
</script>

<style scoped>
.bar-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 40px 40px 0 0;
  background-color: #081121;
  z-index: 9;
}

.bar-icon {
  margin-bottom: 3px;
  width: 30px;
  /* height: 30px */
}

.bar-wrap {
  display: flex;
}

.bar-item {
  flex: 1;
  color: #4E5F7E;
  font-size: 12px;
  text-align: center;
}

.bar-float .bar-icon {
  margin-bottom: 20px;
  width: 80px;
}

.placeholder {
  height: 100px;
}

.bar-active {
  color: #fff;
}
</style>