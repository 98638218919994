import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/Team',
    name: 'Team',
    component: () => import('../views/user/Team.vue')
  },
  {
    path: '/Chongzhi',
    name: 'Chongzhi',
    component: () => import('../views/wallet/Chongzhi.vue')
  },
  {
    path: '/Tixian',
    name: 'Tixian',
    component: () => import('../views/wallet/Tixian.vue')
  },
  {
    path: '/Zhuanzhang',
    name: 'Zhuanzhang',
    component: () => import('../views/wallet/Zhuanzhang.vue')
  },
  {
    path: '/ZhuanzhangRecord',
    name: 'ZhuanzhangRecord',
    component: () => import('../views/wallet/ZhuanzhangRecord.vue')
  },
  {
    path: '/Zhuanhuan',
    name: 'Zhuanhuan',
    component: () => import('../views/wallet/Zhuanhuan.vue')
  },
  {
    path: '/ZhuanhuanRecord',
    name: 'ZhuanhuanRecord',
    component: () => import('../views/wallet/ZhuanhuanRecord.vue')
  },
  {
    path: '/TouziRecord',
    name: 'TouziRecord',
    component: () => import('../views/wallet/TouziRecord.vue')
  },
  {
    path: '/Share',
    name: 'Share',
    component: () => import('../views/user/Share.vue')
  },
  {
    path: '/Bill',
    name: 'Bill',
    component: () => import('../views/wallet/Bill.vue')
  },
  {
    path: '/Bonus',
    name: 'Bonus',
    component: () => import('../views/wallet/Bonus.vue')
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/news/News.vue')
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('../views/news/NewsDetails.vue')
  },
  {
    path: '/ZhubiRecord',
    name: 'ZhubiRecord',
    component: () => import('../views/wallet/ZhubiRecord.vue')
  },
  {
    path: '/Jingcai',
    name: 'Jingcai',
    component: () => import('../views/jingcai/index.vue')
  },
  {
    path: '/jingcaiDetails',
    name: 'jingcaiDetails',
    component: () => import('../views/jingcai/jingcaiDetails.vue')
  },
  {
    path: '/JingcaiRecord',
    name: 'JingcaiRecord',
    component: () => import('../views/jingcai/JingcaiRecord.vue')
  },
  {
    path: '/JingcaiSource',
    name: 'JingcaiSource',
    component: () => import('../views/jingcai/JingcaiSource.vue')
  },
  {
    path: '/Guoku',
    name: 'Guoku',
    component: () => import('../views/guoku/Guoku.vue')
  },
  {
    path: '/GuokuRecord',
    name: 'GuokuRecord',
    component: () => import('../views/guoku/GuokuRecord.vue')
  },
  {
    path: '/Zhubichi',
    name: 'Zhubichi',
    component: () => import('../views/guoku/Zhubichi.vue')
  },
  {
    path: '/BurnRecord',
    name: 'BurnRecord',
    component: () => import('../views/guoku/BurnRecord.vue')
  },
  {
    path: '/TixianRecord',
    name: 'TixianRecord',
    component: () => import('../views/wallet/TixianRecord.vue')
  },
  {
    path: '/Official',
    name: 'Official',
    component: () => import('../views/official/Official.vue')
  },
  {
    path: '/Help',
    name: 'Help',
    component: () => import('../views/official/Help.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/shop/index.vue')
  },
  {
    path: '/shopCart',
    name: 'shopCart',
    component: () => import('../views/shop/shopCart.vue')
  },
  {
    path: '/shopDetails',
    name: 'shopDetails',
    component: () => import('../views/shop/shopDetails.vue')
  },
  {
    path: '/shopOrder',
    name: 'shopOrder',
    component: () => import('../views/shop/shopOrder.vue')
  },
  {
    path: '/shopOrderDetails',
    name: 'shopOrderDetails',
    component: () => import('../views/shop/shopOrderDetails.vue')
  },
  {
    path: '/shopSearch',
    name: 'shopSearch',
    component: () => import('../views/shop/shopSearch.vue')
  },
  {
    path: '/luckyBuy',
    name: 'luckyBuy',
    component: () => import('../views/shop/luckyBuy.vue')
  },
  {
    path: '/luckyBuyPay',
    name: 'luckyBuyPay',
    component: () => import('../views/shop/luckyBuyPay.vue')
  },
  {
    path: '/luckyBuyRule',
    name: 'luckyBuyRule',
    component: () => import('../views/shop/luckyBuyRule.vue')
  },
  {
    path: '/luckyBuyCash',
    name: 'luckyBuyCash',
    component: () => import('../views/shop/luckyBuyCash.vue')
  },
  {
    path: '/shopStore',
    name: 'shopStore',
    component: () => import('../views/shop/shopStore.vue')
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('../views/address/index.vue')
  },
  {
    path: '/addressAdd',
    name: 'addressAdd',
    component: () => import('../views/address/addressAdd.vue')
  },
  {
    path: '/addressUpdate',
    name: 'addressUpdate',
    component: () => import('../views/address/addressUpdate.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/problem',
    name: 'problem',
    component: () => import('../views/user/problem.vue')
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/merchant/index.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/merchant/apply.vue')
  },
  {
    path: '/applyRecoed',
    name: 'applyRecoed',
    component: () => import('../views/merchant/applyRecoed.vue')
  },
  {
    path: '/applyDetails',
    name: 'applyDetails',
    component: () => import('../views/merchant/applyDetails.vue')
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import('../views/merchant/goodsList.vue')
  },
  {
    path: '/goodsAdd',
    name: 'goodsAdd',
    component: () => import('../views/merchant/goodsAdd.vue')
  },
  {
    path: '/goodsUpdate',
    name: 'goodsUpdate',
    component: () => import('../views/merchant/goodsUpdate.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/merchant/orderList.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/merchant/orderDetails.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat/index.vue')
  },
  {
    path: '/chatList',
    name: 'chatList',
    component: () => import('../views/chat/chatList.vue')
  },
  {
    path: '/PhoneBill',
    name: 'PhoneBill',
    component: () => import('../views/shop/PhoneBill.vue')
  },
  {
    path: '/hashRecord',
    name: 'hashRecord',
    component: () => import('../views/jingcai/hashRecord.vue')
  },
  {
    path: '/destroyRecords',
    name: 'destroyRecords',
    component: () => import('../views/jingcai/destroyRecords.vue')
  },
  {
    path: '/ChongzhiRecord',
    name: 'ChongzhiRecord',
    component: () => import('../views/wallet/ChongzhiRecord.vue')
  },
  {
    path: '/Swap',
    name: 'Swap',
    component: () => import('../views/wallet/Swap.vue')
  },
  {
    path: '/Remove',
    name: 'Remove',
    component: () => import('../views/wallet/Remove.vue')
  },
  {
    path: '/BurnRecordUser',
    name: 'BurnRecordUser',
    component: () => import('../views/wallet/BurnRecord.vue')
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/user/community.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/wallet/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, form ,next) => {
  document.body.scrollTop = 0;
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  next()
})

export default router
