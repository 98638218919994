import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import Empty from './components/Empty'
import Headbar from './components/Headbar'
import TabBar from './components/TabBar'
import NavBar from './components/NavBar'
import ShopNavBar from './components/ShopNavBar'
import { createI18n } from 'vue-i18n';
import  {getCookie}  from './assets/js/cookie.js';
import 'vant/lib/index.css';


// vant 全局默认英文
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);

// 设置固定标题
document.title = "SUPER TOSS"

const app = createApp(App);

let language = utils.getloc("language") ? utils.getloc("language") : 'ch'
const i18n = createI18n({
	locale: getCookie('PLAY_LANG', language),    // 语言标识
    messages: {
      'ch': require('./assets/js/language/ch'),
      'en': require('./assets/js/language/en'),
      'cht': require('./assets/js/language/cht'),
      'jp': require('./assets/js/language/jp'),
      'kor': require('./assets/js/language/kor'),
      'th': require('./assets/js/language/th'),
    }
})


import request from './assets/js/request.js';
import utils from './assets/js/utils.js';
import config from './assets/js/config.js'

app.config.globalProperties.$request = request;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$config = config

import signalr from "@/assets/js/signalr.js";
app.config.globalProperties.$ws = signalr

app.use(Quasar, {
  plugins: {
    Dialog,
		Loading,
		Notify
  }
})

import { AddressEdit } from 'vant';
import { AddressList } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Stepper } from 'vant';
import 'vant/es/address-edit/style';
import 'vant/es/address-list/style';
import 'vant/es/cell/style';
import 'vant/es/cell-group/style';
import 'vant/es/stepper/style';

app.component('Empty', Empty)
app.component('Headbar', Headbar)
app.component('TabBar', TabBar)
app.component('NavBar', NavBar)
app.component('ShopNavBar', ShopNavBar)
app.use(AddressEdit);
app.use(AddressList);
app.use(Cell);
app.use(CellGroup);
app.use(Stepper);
app.use(quasarUserOptions);
app.use(router);
app.use(store);
app.use(i18n)
app.mount('#app');
